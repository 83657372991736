'use strict';

/* Polyfills */
if (typeof NodeList.prototype.forEach === "undefined") {
	NodeList.prototype.forEach = Array.prototype.forEach;
}
if (typeof HTMLCollection.prototype.forEach === "undefined") {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

/* App Class */
class App {

	constructor(){
		this.nav = new Nav(document.querySelector('nav'), document.querySelector('[data-action="menu"]'), document.querySelector('.header-bottom'));
		this.ymap = new YMap(document.getElementById('map'));
		//console.log(this)
	}

	init(){
		if (this.nav.el) this.nav.init();
		if (this.ymap.el) ymaps.ready(this.ymap.init);
	}

}

/* Nav Class */
class Nav {

	constructor(el=false, control=false, panel=false){
		this.el = el;
		this.control = control;
		this.panel = panel;
		this.items = [];
	}

	init(){
		const items = this.el.querySelectorAll('a');

		if (items.length) items.forEach( item => {
			this.items.push(item);
			/*
			** Only prod
			*/
			if (location.href === item.href) item.classList.add('active');
		} );

		if (this.control && this.panel) this.control.addEventListener('click', e => {
			e.preventDefault();
			this.panel.classList.toggle('open');
		});

		window.addEventListener('resize', e => {
			this.panel.classList.remove('open');
		})

	}

}

/* Map Class */
class YMap {

	constructor(el=false){
		this.el = el;
	}

	init(){
		let map =  new ymaps.Map('map', {
			center: [57.759751566863024,40.95756249999999],
			zoom: 16,
			controls: [],
			behaviors: ['drag', 'scrollZoom']
		});
		let place = new ymaps.Placemark(map.getCenter(), {
			balloonContent: 'Офис Polygems'
		},
		{
			iconLayout: 'default#image',
			iconImageHref: '/assets/images/pin.png',
			iconImageSize: [30, 38],
			iconImageOffset: [-15, -19]
		});
		let zoom = new ymaps.control.ZoomControl({
			options: {
				size: "small"
			}
		});
		map.geoObjects.add(place);
		map.controls.add(zoom);
	}

}

const app = new App();

window.addEventListener('DOMContentLoaded', () => app.init());